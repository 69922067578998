import { cloneElement , useEffect} from "react";

const Overlay = ({ isOpened, toggleOverlay, styles=[], children }) => {
  const closeOverlay = () => {
    toggleOverlay();
  };
  const childClick = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.body.style.paddingRight = `${scrollbarWidth}px`;
      document.getElementById('header').style.paddingRight = `${scrollbarWidth}px`;
      document.body.classList.add('no-scroll');

    return () => {
      document.body.style.paddingRight = '';
      document.getElementById('header').style.paddingRight = ``;
      document.body.classList.remove('no-scroll');
    };
  }, []);


  return (
    <div className={`searchOverlay ${isOpened && "active"}`} onClick={closeOverlay}>
      <div className={`overlayContent ${[...styles]}`} onClick={childClick}>
        {cloneElement(children, {closeOverlay})}
      </div>
    </div>
  );
};

export default Overlay;

import LaButiqueImg from 'images/butique/laButique.webp';
import LaButiqueImgMobile from 'images/butique/laButique-mobile.webp';
import CoffeeImg from 'images/icons/coffee.svg';
import CatImg from 'images/icons/cat.svg';
import StarImg from 'images/icons/star.svg';
import CubeImg from 'images/icons/cube.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const LaButique = ({displayCenterText}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();


    const laBoutique = ()=>{
        navigate('/laButique')
    }

    return ( 
    <section id="laButique" className='container--v2'>
        <div className='laButique--background'>
            <div>
                <picture>
                    <source media='(min-width:768px)' srcSet={LaButiqueImg}/>
                    <img src={LaButiqueImgMobile} alt="" />
                </picture>
            </div>
            {
                displayCenterText &&  
            <div className='laButique-content'>
                <h2>ჩილერები</h2>
                <p>{t('la_boutique_text')}</p>
                <button onClick={laBoutique}>ჩილერების ნახვა</button>
            </div>
            }

        </div>

        <div className='laButique--pros'>
            <div className='laButique--pros-cell'>
                <img src={CoffeeImg} alt="icon" />
                <p>{t('⁠cozy_coffee_and_tea_space')}</p>
            </div>
            <div className='laButique--pros-cell'>
                <img src={CatImg} alt="icon" />
                <p>{t('pets_are_allowed')}</p>
            </div>
            <div className='laButique--pros-cell'>
                <img src={StarImg} alt="icon" />
                <p>⁠{t('400_handpicked_gems')}</p>
            </div>
            <div className='laButique--pros-cell'>
                <img src={CubeImg} alt="icon" />
                <p>{t('popup_hub_for_brands')}</p>
            </div>
        </div>
    </section> );
}
 
export default LaButique;
import menu_icon from 'images/icons/menu.svg';
import logo from 'images/icons/logo.svg';
import search_icon from 'images/icons/search.svg';
import cart_icon from 'images/icons/shopping.svg';
import closeIcon from 'images/icons/close.svg';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Search from './Search';
import Overlay from './Overlay';
import Cart from './Cart';
import { CartContext } from 'app/Context/CartContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clothingTypeMulti } from 'globalVariables/globalVariables';


const Header = () => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const [cartPrevCount, setPrevCount] = useState(0);


    const [mobileShopShow, setMobileShop] = useState(false);
    const [menuOpened, openMenu] = useState(false);
    // const [searchIsActive, setSearch] = useState(false);
    const [cartOpened, setCartOpened] = useState(false);
    const {cart}= useContext(CartContext);

    const toggleCart = ()=>{
        if(cart.length === 0 && !cartOpened){return};
        setCartOpened(!cartOpened);
    }



    useEffect(()=>{
        if(cartPrevCount < cart.length){
            setCartOpened(true);
        }

        setPrevCount(cart.length);
    },[cart])

    useEffect(()=>{
        const language = localStorage.getItem('language');
        if(!language){
            localStorage.setItem('language', 'en');
            i18n.changeLanguage('en');
        }else{
            localStorage.setItem('language', language);
            i18n.changeLanguage(language);
        }
    },[])


    const menuClick = ()=>{
        openMenu(true);
    }

    const onShop = ()=>{
        setMobileShop(!mobileShopShow);
    }

    const closeMenu = ()=>{
        openMenu(false)
    }

    const handleNavigate = (type)=>{
        navigate(`/shop?clothingType=${type}`);
        onShop();
        closeMenu();
    }

    const changeLanguage = (lng) => {
        localStorage.setItem('language', lng)
        i18n.changeLanguage(lng);
    };

    return ( 
            <header id='header' className='header--mobile'>
                <div className='languageSwitch container laptopAndDown'>
                    <p className={`en ${i18n.language === 'en' && 'active'}`} onClick={() => changeLanguage('en')}>EN</p>
                    <p className={`fr ${i18n.language === 'fr' && 'active'}`} onClick={() => changeLanguage('fr')}>FR</p>
                </div>
                <div className='header--main container'>
                    <div className='header--logo'>
                        <img src={menu_icon} alt="menu" className='laptopAndDown sandwichMenu'  onClick={menuClick} />
                        {/* <NavLink to="/"><img src={logo} className='logo' alt="logo" /></NavLink> */}
                        <NavLink to="/"><h1 className='logo'>ჩილლერ</h1></NavLink>
                    </div>


                    {/* <nav className='navigation-desktop'>
                        <ul className='mainNav'>
                            <li className='shopNav'>
                                <NavLink to='/shop'>{t('shop')}</NavLink>
                                <div className='shopNav--expand'>
                                    <ul className='shopNav--col1'>
                                        {
                                            clothingTypeMulti.slice(0,6).map(type=>(
                                                <li key={type.en} onClick={()=>{handleNavigate(type.en)}}><NavLink to='/shop'>{i18n.language==='en' ? type.en : type.fr}</NavLink></li>
                                            ))
                                        }
                                    </ul>
                                    <ul className='shopNav--col2'>
                                        {
                                            clothingTypeMulti.slice(6).map(type=>(
                                                <li key={type.en} onClick={()=>{handleNavigate(type.en)}}><NavLink to='/shop'>{i18n.language==='en' ? type.en : type.fr}</NavLink></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </li>
                            <li><NavLink to={'/laButique'}>ნაწილები</NavLink></li>
                            <li><NavLink to='/our_story'>{t('our_story')}</NavLink></li>
                            <li><NavLink to='/la_plongeuse'>LA PLONGEUSE</NavLink></li>

                           
                        </ul>
                    </nav> */}

                    <div className='header--icons'>
                        {/* <img src={search_icon} alt="search_icon" onClick={()=>{setSearch(!searchIsActive)}} /> */}
                        {/* <img src={profile_icon} alt="profile_icon" /> */}
                        <div className='cart' onClick={toggleCart}>
                            <img src={cart_icon} alt="cart_icon" />
                            <span className='cartCount'>{cart.length}</span>
                        </div>
                        {
                            !menuOpened ?
                            <>
                            </> 
                            :
                            <img src={closeIcon} alt="X"  className='closeMenu'/>
                        }
                    </div>
                    

                    <div className='displayFlex languageSwitch--laptop'>
                        <p className={`en ${i18n.language === 'en' && 'active'}`} onClick={() => changeLanguage('en')}>EN</p>
                        <p className={`fr ${i18n.language === 'fr' && 'active'}`} onClick={() => changeLanguage('fr')}>FR</p>
                    </div>
                </div>

                <div className={`mobileNav__wrap ${menuOpened && "active"}`}>
                    <div className='mobileNav__wrap__head'>
                        <img className='logo' src={logo} alt="Logo" />
                        <img onClick={closeMenu} className='close' src={closeIcon} alt="X" />
                    </div>
                    
                    {/* <nav className={`mobileNav ${mobileShopShow && "active"}`}>
                        <ul className='mobileNavMain'>
                            <li onClick={onShop}><NavLink>{t('shop')}</NavLink></li>
                            <li ><NavLink to={'/laButique'} onClick={closeMenu}>LA BOUTIQUE</NavLink></li>
                            <li><NavLink to='/our_story' onClick={closeMenu}>{t('our_story')}</NavLink></li>
                            <li><NavLink to='/la_plongeuse' onClick={closeMenu}>LA PLONGEUSE</NavLink></li>
                        </ul>

                        <ul className={`mobileShop ${mobileShopShow && "active"}`}>
                            {
                                clothingTypeMulti.map(type=>(
                                    <li key={type.en} onClick={()=>{handleNavigate(type.en)}}><NavLink>{i18n.language==='en' ? type.en : type.fr}</NavLink></li>
                                ))
                            }
                        </ul>
                    </nav> */}
                </div>


                {/* { searchIsActive ? <Overlay isOpened={searchIsActive} toggleOverlay={toggleSearch} styles={['overlayContentSearch']}><Search/></Overlay> : null } */}
                { cartOpened ? <Overlay isOpened={cartOpened} toggleOverlay={toggleCart} styles={['overlayContentFilter']}><Cart/></Overlay> : null }
            </header>
     );
}
 
export default Header;